// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  isDev: true,
  appVersion: 'v723demo1',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  sallaUrl: 'https://salla.4ja.ai/', // dev
  sallaExternalURL: 'https://apps.salla.sa/ar/app/195711185',
  cdnUrl: 'https://ams3.digitaloceanspaces.com/4jawaly-dev/networks/',
  PDF_URL: 'https://pdfv1.viewclass.com/web/viewer.html?file=',
  verification_4jawaly: 'https://verification.4jawaly.com/',
  accountant_apiUrl: 'https://ca2-dev.viewclass.com/api/v1/',
  sma: {
    apiUrl: 'https://api-sms-dev.4ja.ai/api/v1/',
  },
  archive: {
    apiUrl: 'https://api-archive.4ja.ai/api',
  },
  email: {
    apiUrl: 'https://m2s.4ja.ai/',
  },
  take: 15,
  // apiUrl: 'https://api-4j.4jawaly.com/api/v1', // Production
  apiUrl: 'https://api-4j-dev.4ja.ai/api/v1', // dev
  siteUrl: 'https://user-dev.4ja.ai/',
  siteKey: '6LedXNUSAAAAABuSDjDAgRXWRGWZebTuMIOYnVqV',
  recaptcha: {
    v3Key: '6LcVoEkjAAAAAFWIZQ6ueyJNpvgL0SXOpSmJcYhF',
  },
  publicKey: 'BDLKu7Qu8nM3lnkQ6TaSpM5s9JmZ1qmFVRtthQQgVKAsIaGw8_f09HclPNT1n-N7QdsHZ0pkK6IA2-ziJzfqJKY',
  pusher: {
    key: '51be68c16014eba693cf',
    cluster: 'eu',
  },
  excelApiUrl: 'https://excel-dev.4ja.ai',
  excelWS: 'wss://ws-excel-dev.4ja.ai/status',
  stripeKey: 'pk_test_51LQ4upLyWqXvHGw87l4P8Ss9evguEZujDwMiR8PduPEEKqPSwlC13FvRsB4nTvhf917Eojpn2Efun3fcz2x3BUpJ000TtcH37h',
  number_iso: 'SA,AE,EG,QA,KW,OM,LB,MA,TN,DZ,IQ,BH,JO,SY,PS,LY,SD,YE,MR,DJ,KM,SO',
  host: {
    apiUrl: 'https://dh-api.4ja.ai/api/',
  },
  whats: {
    apiUrl: 'http://whatsapp.4ja.ai/api/v1/',
    bootApiUrl: 'https://chapi.4ja.ai/',
    newBoot : 'https://bbot.4ja.ai/',
    sakenkom : 'https://chatbot.4ja.ai/',
  },
  maxUploadSize: 2,
  hyperPay: {
    apiUrl: 'https://test.oppwa.com/v1/paymentWidgets.js',
  },
  gust: {
    apiUrl: 'https://resellers.4jawaly.com/api/',
  },
  appInsight: {
    instrumentationKey: 'c464e511-0e7b-4e62-8f67-82f2fa36c3ee',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
