import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class UserService {
  baseURL: string = environment.apiUrl;
  invoiceDataLoaded =  false;
  userInvoiceDta: BehaviorSubject<any> = new BehaviorSubject(null);
  userInvoiceDta$ = this.userInvoiceDta.asObservable();
  

  constructor(private _http: HttpClient) {}

  /**
   * Store File To Whats Gallery
   * @returns
   */
  setTelegramUser(body): Observable<any> {
    const url = `${this.baseURL}/account/profile/set-telegram-username`
    return this._http.patch(url, body)
  }

  /**
   * Create Api Token
   * @returns
   */
  addApiToken(body): Observable<any> {
    const url = `${this.baseURL}/account/profile/tokens`
    return this._http.post(url, { ...body })
  }

  getDashboardStatistics(): Observable<any> {
    const url = `${this.baseURL}/account/profile/dashboard`
    return this._http.get(url)
  }

  /**
   * Update Api Token
   * @returns
   */
  updateApiToken(body, id): Observable<any> {
    const url = `${this.baseURL}/account/profile/tokens/${id}/update`
    return this._http.patch(url, body)
  }

  /**
   * Get Api Token
   * @returns
   */
  getApiTokens(body): Observable<any> {
    const url = `${this.baseURL}/account/profile/tokens`;
    return this._http.patch(url,body);
  }

  /**
   * Delete Api Token
   * @returns
   */
  deleteApiToken(id): Observable<any> {
    const url = `${this.baseURL}/account/profile/tokens/${id}`
    return this._http.delete(url)
  }

  setIdNumber(body): Observable<any> {
    const url = `${this.baseURL}/account/profile/set-id-number`
    return this._http.patch(url, body)
  }

  setAllowsIps(body): Observable<any> {
    const url = `${this.baseURL}/account/profile/set-allowed-ips`
    return this._http.post(url, body)
  }

  acceptTermsAndConditions(body): Observable<any> {
    const url = `${this.baseURL}/account/profile/accept-agreements`
    return this._http.post(url, body)
  }

  // User Invoice
  /**
   * Get user Invoice data
   * @returns
   */
  getUserInvoiceData(params?): Observable<any> {
    const url = `${this.baseURL}/account/profile/invoice_data`;
    return this._http.get(url, {
      params,
    });
  //   return of({
  //     "code": 200,
  //     "message": "\u0639\u0645\u0644\u064a\u0629 \u0646\u0627\u062c\u062d\u0629",
  //     "item": {
  //         "account_id": 2778,
  //         "client_business_name": "dssd",
  //         "client_first_name": "dssd",
  //         "client_last_name": "kllkklklkl",
  //         "client_email": "gad@gmail.com",
  //         "client_address1": "kllkklklkl",
  //         "client_address2": null,
  //         "client_postal_code": "kllkklklkl",
  //         "tax_number": "",
  //         "district": "1",
  //         "governorate": "1",
  //         "odoo_country": "1",
  //         "building_number": "1",
  //         "city_id": 1,
  //         "country_id": 1,
  //         "client_state": 1,
  //         "client_active_secondary_address": 6,
  //         "client_secondary_name": 88,
  //         "client_secondary_address1": 88,
  //         "client_secondary_address2": 88,
  //         "client_secondary_postal_code": 88,
  //         "client_secondary_city_id": 88,
  //         "client_secondary_country_id": 44,
  //         "account_commercial_record_id": null,
  //         "created_at": "2025-02-27T10:58:02.000000Z",
  //         "updated_at": "2025-02-27T11:00:21.000000Z",
  //         "account_commercial_record": null,
  //         "country": {
  //             "id": 2,
  //             "iso_code": "sa",
  //             "zone": "Asia\/Riyadh",
  //             "created_at": "2021-02-11T11:24:36.000000Z",
  //             "updated_at": "2023-09-25T08:40:18.000000Z",
  //             "current_language": {
  //                 "country_id": 2,
  //                 "language_id": 1,
  //                 "title": "\u0627\u0644\u0633\u0639\u0648\u062f\u064a\u0629"
  //             }
  //         },
  //         "city": {
  //             "id": 251,
  //             "country_id": 2,
  //             "created_at": "2021-02-11T11:24:36.000000Z",
  //             "updated_at": "2021-02-11T11:24:36.000000Z",
  //             "current_language": {
  //                 "city_id": 251,
  //                 "language_id": 1,
  //                 "title": "\u0627\u0644\u0627\u0628\u0648\u0629"
  //             }
  //         },
  //         "secondary_country": null,
  //         "secondary_city": null
  //     }
  // })

  }

  /**
   * Update user Invoice data
   * @returns
   */
  setUserInvoiceData(body): Observable<any> {
    const url = `${this.baseURL}/account/profile/invoice_data`
    return this._http.patch(url, body)
  }

  // Update User Data

  changeUserData(body): Observable<any> {
    const url = `${this.baseURL}/account-change-data`
    return this._http.post(url, body)
  }

  changeUserName(body): Observable<any> {
    const url = `${this.baseURL}/account/profile/update-name`
    return this._http.post(url, body)
  }

  verifyUserData(body): Observable<any> {
    const url = `${this.baseURL}/account-change-data/verify`
    return this._http.post(url, body)
  }

  rejectUserDataChangeRequest(body): Observable<any> {
    const url = `${this.baseURL}/account-change-data/reject`
    return this._http.post(url, body)
  }

  resendMailVerification(body): Observable<any> {
    const url = `${this.baseURL}/account-change-data/resend-verify`
    return this._http.post(url, body)
  }

  resendPhoneVerification(body): Observable<any> {
    const url = `${this.baseURL}/account-change-data/resend-verify-user-sms`
    return this._http.post(url, body)
  }

  resendWhatsVerification(body): Observable<any> {
    const url = `${this.baseURL}/account-change-data/resend-verify-user-whatsapp`
    return this._http.post(url, body)
  }

  getWhatsappTokens(params?): Observable<any> {
    const url = `${this.baseURL}/account/whatsapp/numbers/list-tokens`
    return this._http.get(url, {
      params,
    })
  }

  getUserDevicesList() {
    const url = `${this.baseURL}/account/devices`
    return this._http.get(url);
  }

  deleteUserDevice(key) {
    const url = `${this.baseURL}/account/devices/${key}`
    return this._http.delete(url);
  }
}